html,
body,
#root {
    height: 100%;
}

body {
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    font-family: QuickSand, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Menlo, Monaco, 'SFMono-Regular', Consolas, 'Liberation Mono', Courier, monospace;
    background-color: #f9f2f4;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #c7254e;
    border-radius: 4px;
}
