/**/

.ant-table-cell {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 2px 15px !important ;
}

#bg-layer {
    background-color: #fafafa;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -100;
}

hr {
    opacity: 0.4;
}

.unselectable-span {
    user-select: none;
}

.hide-on-demand {
    display: none !important;
}

.disabled {
    opacity: 0.4;
    cursor: hand;
    pointer-events: none;
}

.pre-line-content {
    white-space: pre-wrap;
}

.algo-card {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 40px;
    background-color: #eedaff;
    padding: 16px;
    border-radius: 10px;
    border: 2px solid #d5a1ff;
}

.algo-button-preselected {
    background-color: #d5e0ff;
    border-radius: 25px;
    border: 2px solid #b5c9ff;
}

.prism-header {
    height: 150px !important;
    width: 100%;
    background-color: white;
}

.logs-output {
    font-family: Menlo, Monaco, 'SFMono-Regular', Consolas, 'Liberation Mono', Courier, monospace !important;
    overflow: scroll;
    height: 250px;
    border: 2px solid #cccccc;
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 10px;
    font-size: 90%;
    width: 100%;
}
